<template>
    <content-wrapper>
        <w-data-table
            :headers="headers"
            :filter-by="[ 'title', 'description' ]"
            searchable
            itemKey="uuid"
            :items="items"
        >
            <template #item.group="{item}">
                {{ lessonTypeGroupLabel(item.group) }}
            </template>

            <template #actions>
                <w-btn-action
                    :text="$trans('Add lesson type')"
                    icon="ADD"
                    @click="onShowForm"
                />
            </template>
            <template #item.actions="{ item }">
                <div class="d-flex justify-end">
                    <v-btn
                        icon
                        color="success"
                        @click="onShowForm(item)"
                    >
                        <w-icon size="20" value="mdi-pencil"/>
                    </v-btn>
                    <v-btn
                        icon
                        color="error"
                        @click="onDelete({}, item)"
                    >
                        <w-icon size="20" value="mdi-delete"/>
                    </v-btn>
                </div>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isDrawer"
            :title="$trans('Lesson type')"
            :loading="loading"
        >
            <div class="pa-4">
                <w-simple-form
                    :key="'groupForm' + formKey"
                    :fields="formFields"
                    :item="item"
                    :errors="errors"
                    deletable
                    :disabled="loading"
                    @save="onStore"
                    @update="onUpdate"
                    @delete="onDelete"
                >
                    <template #field.group="{data, attrs, errors, on}">
                        <v-select
                            v-model="data.group"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                        >
                            <template #item="selectItem">
                                <v-list-item v-on="selectItem.on" v-bind="selectItem.attr" dense>
                                    <v-list-item-title>{{ selectItem.item.label }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ selectItem.item.description }}</v-list-item-subtitle>
                                </v-list-item>
                            </template>
                        </v-select>
                    </template>
                </w-simple-form>
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { required } from '@/utils/validation'
import {
    deleteLessonType,
    getLessonTypesCollection,
    storeLessonType,
    updateLessonType
} from '@apps/school/api/lessonTypes'

export default {
    name: 'LessonTypesManage',
    components: { ContentWrapper },
    computed: {
        headers() {
            return [
                {
                    text: this.$trans('Title'),
                    value: 'title'
                },
                {
                    text: this.$trans('Description'),
                    value: 'description'
                },
                {
                    text: this.$trans('Group'),
                    value: 'group'
                },
                {
                    align: 'right',
                    value: 'actions',
                    sortable: false
                }
            ]
        },
        lessonTypeGroups() {
            return this.$store.getters['school/lessonTypeGroups']
        },
        formFields() {
            return [
                {
                    name: 'title',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Title'),
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'description',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Description'),
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'group',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Group'),
                        outlined: true,
                        dense: true,
                        items: this.lessonTypeGroups || [],
                        itemText: 'label',
                        itemValue: 'value',
                        rules: [
                            required
                        ]
                    }
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            errors: {},
            isDrawer: false,
            formKey: 0,
            item: {},
            items: [],
            filterByLevel: null,
            draggedData: null
        }
    },
    created() {
        this.fetchLessonTypes()
    },
    methods: {
        lessonTypeGroupLabel(group) {
            const groupType = this.lessonTypeGroups.find(o => o.value === group)

            return groupType ? groupType.label : null
        },
        onShowForm(item) {
            this.item = item || {}
            this.formKey++
            this.isDrawer = true
        },
        async fetchLessonTypes() {
            this.$lprogress.begin()

            try {
                const response = await getLessonTypesCollection()
                this.items = response.data.data
            } finally {
                this.$lprogress.end()
            }
        },
        async onStore(data) {
            this.loading = true
            try {
                await storeLessonType(data)
                await this.fetchLessonTypes()
                this.isDrawer = false
                this.loading = false
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.loading = false
            }
        },
        async onUpdate(data, item) {
            this.loading = true
            try {
                await updateLessonType(item.uuid, data)
                await this.fetchLessonTypes()
                this.isDrawer = false
                this.loading = false
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.loading = false
            }
        },
        async onDelete(data, item) {
            this.isDrawer = false

            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Delete')} ${item.title}`
            }))

            if (!isConfirm) {
                return
            }

            this.$lprogress.begin()

            try {
                await deleteLessonType(item.uuid)
                await this.fetchLessonTypes()
                this.$lprogress.end()
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
